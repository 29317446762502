import { render, staticRenderFns } from "./prod_regi.vue?vue&type=template&id=1e18b69d&"
import script from "./prod_regi.vue?vue&type=script&lang=js&"
export * from "./prod_regi.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports