<template>

    <div class="pu_wrapper pu_web">

        <!--================ inc head top =================-->
        <inc_user_head />
        <inc_user_top />
        <!--================ //inc head top =================-->

        <div class="pu_board">

            <!--================ inc left =================-->
            <inc_user_left />
            <!--================ //inc left =================-->

            <div class="pu_row">
                <div class="pu_contents pu_min_height_700">

                    <div class="pu_row">

                        <div class="pu_col_lg_3" v-for="(row, idx) in list_1">
                            <div class="pu_item_wrpper" @click="showProdDetail(row.NO)">
                                <div class="pu_item_pic" v-bind:style="{ backgroundImage: 'url(' + row.URL_IMG_ITEM_THUM + ')' }">
                                </div>
                                <div class="pu_item_cont">
                                    <p class="pu_item_title">{{ row.TITLE }}</p>

                                    <p v-if="row.PRICE > 0" 
                                       class="pu_itme_price">{{ $ConMoney(row.PRICE) }} 원</p>

                                    <p v-if="row.PRICE < 1"
                                       class="pu_itme_price">전화문의</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

        <!--================ inc foot =================-->
        <inc_user_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

                , gubun: ""    // 구분

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                this.getSearchData(1);

            }

            // 데이터 조회
            , getSearchData (curPage) {

                var qs = this.$GetQueryMap();

                var data = {};
                data["gubun_page"] = this.gubun_page;
                data["curPage"] = curPage;
                data["gubun"] = qs.page_gubun;

                // API 조회
                this.$SendPost("/vue_api/user/prod/list", data, this.searchDataReturn);

            }

            // 데이터 리턴
            , searchDataReturn (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.rownum = data.ROWNUM;
                        this.paging = data.PAGING;
                        this.list_1 = data.LIST_1;
                        this.list_2 = data.LIST_2;
                        this.list_3 = data.LIST_3;

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }

            // 제품 상세 보기
            , showProdDetail(item_no) {

                var dw = $(window).width();
                var dh = $(window).height();

                dw *= (1 - 0.2);
                dh *= (1 - 0.2);

                // 모바일이 아니면 크기 제한
                if (!this.$IsMobile()) {
                    dw = Math.min(800, dw);
                    dh = Math.max(800, dh);
                }

                FrameLayer.open("./prod_layer?no=" + item_no, dw, dh);

            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>