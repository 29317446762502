<template>

    <!--================ Left Menu Area =================-->

    <div class="pu_left">
        <div class="pu_row">
            <div class="pu_contents">

                <!--아래 div 가 있어야 메뉴가 바로바로 바뀜-->
                <div style="display:none;">
                    {{ getBase.adminMenuName_2d }}
                </div>
                <!--//아래 div 가 있어야 메뉴가 바로바로 바뀜-->

                <ul>

                    <li>
                        <a>{{ getBase.adminMenuName_1d }}</a>

                        <ul>
                            <li v-bind:class="d2.ACTIVE" v-for="(d2, idx_d2) in getBase.arrAdminMenu_2d">
                                <span class="pu_select">
                                    <i class="fas fa-chevron-circle-right"></i>
                                </span>
                                <a href="javascript:;" v-on:click="$PageMove(d2.URL_MAIN)">{{ d2.TITLE }}</a>
                            </li>
                        </ul>
                    </li>

                </ul>
            </div>
        </div>
    </div>

    <!--================ //Left Menu Area =================-->

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                THIS_NAME: "INC LEFT"

            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // code url
                this.getMenu2d();
            }

            // url code
            , getMenu2d() {

                // 데이터
                var data = {};
                data["url"] = this.getBase.pathCur;
                data["qs"] = this.getBase.qsCur;
                data["menu_code_1d"] = this.getBase.adminMenuCode_1d;

                // API 조회
                this.$SendPost("/vue_api/common/menu/zadmin_2d", data, this.getMenu2dReturn);
            }

            // code url 리턴
            , getMenu2dReturn(data, err) {

                if (data) {

                    if (data.MENU_CODE_2D > 0) {
                        this.setBase({ "adminMenuCode_2d": data.MENU_CODE_2D }); // 2뎁스 메뉴코드
                        this.setBase({ "adminMenuName_2d": data.MENU_NAME_2D }); // 1뎁스 메뉴명
                    }

                    this.setBase({ "arrAdminMenu_2d": data.LIST_1 });         // 2뎁스 메뉴
                }

            }
        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {

            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.init();

        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
