<template>

    <div class="pu_wrapper pu_web">

        <!--================ inc head top =================-->
        <inc_user_head />
        <inc_user_top />
        <!--================ //inc head top =================-->

        <form id="regiForm" onsubmit="return false;">

            <div class="pu_board">

                <!--================ inc left =================-->
                <inc_user_left />
                <!--================ //inc left =================-->

                <div class="pu_row">
                    <div class="pu_contents pu_min_height_450">

                        <div class="pu_row">
                            <div class="pu_contents">

                                <div class="pu_row_horizon" style="padding:200px 0px">

                                    <div class="pu_col_lg_4"></div>

                                    <div class="pu_col_lg_3">
                                        <input type="password" name="pw" class="pu_input_text dev_input_must" value="" placeholder="비밀번호를 입력해주세요" style="text-align:center;">
                                    </div>

                                    <div class="pu_col_lg_2">
                                        <a class="dev_btn_submit pu_btn pu_btn_regi" style="height: 34px; line-height: 34px;">
                                            <i class="fas fa-unlock-alt"></i> 비밀번호 확인
                                        </a>
                                    </div>

                                    <div class="pu_col_lg_3"></div>

                                </div>

                            </div>
                        </div>

                        <div class="pu_row" style="margin: 15px 0px;">
                            <div class="pu_contents">

                                <div class="pu_col_lg_1"></div>

                                <div class="pu_col_lg_1">
                                    <a class="pu_btn pu_btn_list" href="javascript:;" v-on:click="$PageMove('./board_qna')">
                                        <i class="fas fa-list-ul"></i> 목록
                                    </a>
                                </div>

                                <div class="pu_col_lg_8"></div>

                                <div class="pu_col_lg_1"></div>

                                <div class="pu_col_lg_1"></div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </form>

        <!--================ inc foot =================-->
        <inc_user_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "CHECK"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // dimm 숨기기
                this.$HideDimmLogo();

            }

            // 전송
            , saveData() {

                // 비밀번호
                var pw = $("[name='pw']");
                if (!pw.val().trim()) {
                    alert("비밀번호를 입력해주세요");
                    pw.focus();
                    return false;
                }

                // dimm 보이기
                this.$ShowDimmLogo();

                var qs = this.$GetQueryMap();
                var data = this.$FormToMap($("#regiForm"));
                data["gubun_page"] = this.gubun_page;
                data["next"] = qs.next;
                data["no"] = qs.no;

                // API 조회
                this.$SendPost("/vue_api/user/board_qna/check", data, this.saveEventReturn);

            }

            // 저장 데이터
            , saveEventReturn(data, err) {

                // dimm 숨기기
                HideWhiteDimm();

            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>