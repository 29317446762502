<template>

    <div class="pu_wrapper">

        <!--================ inc head top =================-->
        <inc_zadmin_head />
        <inc_zadmin_top />
        <inc_zadmin_title />
        <!--================ //inc head top =================-->

        <form id="regiForm">

            <!--전달값-->
            <input type="hidden" name="no" v-model="view_1.NO" />
            <!--//전달값-->

            <div class="pu_board">
                <div class="pu_row">
                    <div class="pu_contents">

                        <!--================ inc left =================-->
                        <inc_zadmin_left />
                        <!--================ //inc left =================-->

                        <div class="pu_body">
                            <div class="pu_row">
                                <div class="pu_contents">

                                    <div class="pu_row">
                                        <div class="pu_contents">

                                            <table class="pu_table_view">
                                                <colgroup>
                                                    <col width="15%">
                                                    <col width="35%">
                                                    <col width="15%">
                                                    <col width="35%">
                                                </colgroup>
                                                <tbody>

                                                    <tr>
                                                        <th>제목</th>
                                                        <td colspan="3">
                                                            <label class="pu_input_text_label">
                                                                <input type="text" name="title" v-model="view_1.TITLE"
                                                                       class="pu_input_text dev_input_must"
                                                                       placeholder="" />
                                                            </label>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>내용</th>
                                                        <td colspan="3">
                                                            <textarea name="contents" class="pu_input_textarea dev_input_must" v-html="view_1.CONTENTS"></textarea>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>공개여부</th>
                                                        <td colspan="3">
                                                            <label class="pu_input_radio_label">
                                                                <input type="radio" name="show_yn"
                                                                       class="pu_input_radio dev_input_must" value="Y" v-model="view_1.SHOW_YN" checked="checked" /> 공개
                                                            </label>
                                                            <label class="pu_input_radio_label">
                                                                <input type="radio" name="show_yn"
                                                                       class="pu_input_radio dev_input_must" value="N" v-model="view_1.SHOW_YN" /> 비공개
                                                            </label>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    <div class="pu_row">
                                        <div class="pu_contents">

                                            <div class="pu_col_lg_1">
                                                <a class="pu_btn pu_btn_list" href="javascript:;" v-on:click="$PageMove('./board_gold_list')">
                                                    <i class="fas fa-list-ul"></i> 목록
                                                </a>
                                            </div>

                                            <div class="pu_col_lg_1">
                                                <a class="dev_btn_delete pu_btn pu_btn_del" v-if="view_1.NO > 0" href="javascript:;">
                                                    <i class="far fa-trash-alt"></i> 삭제
                                                </a>
                                            </div>

                                            <div class="pu_col_lg_9"></div>

                                            <div class="pu_col_lg_1">
                                                <a class="dev_btn_submit pu_btn pu_btn_regi" href="javascript:;">
                                                    <i class="fas fa-edit"></i> 저장
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </form>

        <!--================ inc foot =================-->
        <inc_zadmin_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGIFORM"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , cnt_lim_pic: 5

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                this.getViewData();

            }

            // 데이터 조회
            , getViewData () {

                var qs = this.$GetQueryMap();
                var data = {};
                data["gubun_page"] = this.gubun_page;
                data["no"] = qs.no;

                // API 조회
                this.$SendPost("/vue_api/zadmin/board_gold/view", data, this.viewDataReturn);

            }

            // 데이터 리턴
            , viewDataReturn (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1;  // 뷰

                        if (!this.view_1.SHOW_YN) { // 보임여부
                            this.view_1.SHOW_YN = "Y";
                        }

                        // dimm 숨기기
                        this.$HideDimmLogo();

                    }

                    // ck editor 세팅
                    this.setCkEditor();
                }
            }

            // 저장
            , saveData () {

                // 제목
                var title = $("[name='title']");
                if (!title.val().trim()) {
                    alert("제목을 입력해주세요");
                    title.focus();
                    return false;
                }

                // 내용
                CKEDITOR.instances.contents.updateElement();
                var contents = $("[name='contents']");
                if (!contents.val().trim()) {
                    alert("내용을 입력해주세요");
                    contents.focus();
                    return false;
                }

                // 공개여부                        
                var show_yn = $("[name='show_yn']:checked");
                if (show_yn.length < 1) {
                    alert("공개여부를 선택해주세요");
                    $($("[name='show_yn']")[0]).focus();
                    return false;
                }

                if (confirm(INFO_CONF_SAVE)) {

                    // dimm 보이기
                    this.$ShowDimmLogo();

                    var data = this.$FormToMap($('#regiForm'));
                    data["gubun_page"] = this.gubun_page;

                    // API 조회
                    this.$SendPost("/vue_api/zadmin/board_gold/save", data, this.saveEventReturn);

                }

            }

            // 저장 데이터
            , saveEventReturn (data, err) {

                // dimm 숨기기
                this.$HideDimmLogo();

            }

            // 삭제
            , deleteData: function () {

                if (confirm(INFO_CONF_DELETE)) {

                    // dimm 보이기
                    this.$ShowDimmLogo();

                    // 데이터
                    var data = {};
                    data["gubun_page"] = this.gubun_page;
                    data["no"] = this.view_1.NO;

                    // API 조회
                    this.$SendPost("/vue_api/zadmin/board_gold/del", data, this.deleteDataReturn);

                }

            }

            // 삭제 데이터
            , deleteDataReturn: function (data, err) {

                // dimm 숨기기
                this.$HideDimmLogo();

            }

            // ck editor 세팅
            , setCkEditor: function () {

                setTimeout(() => {

                    try {
                        setCkEditorDefault("contents", "board_gold", this.cnt_lim_pic, ".jpeg,.jpg,.png");
                    } catch (err) {
                        this.setCkEditor();
                    }

                }, 100);
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>