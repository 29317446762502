<template>

    <div class="pu_wrapper pu_web" style="min-width:unset;">

        <!--사진-->
        <div class="pu_layer">

            <div class="pu_wrapper_img pu_col_lg_12">
                <div class="pu_row" style="width: 30%; min-width: 200px; height: 100%; overflow-y:scroll;">
                    <ul class="pu_layer_navi_ul">
                        <li class="pu_layer_navi_li" v-for="(row, idx) in list_1">
                            <div class="dev_layer_navi_img pu_layer_navi_img"
                                 @click="changeImg(row.URL_IMG_ITEM)"
                                 v-bind:style="{ backgroundImage: 'url(' + row.URL_IMG_ITEM + ')' }">
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="pu_row" style="width:65%; height:100%;">
                    <div class="dev_layer_main_img pu_layer_main_img"></div>
                </div>
            </div>

            <div class="pu_col_lg_12">

                <div class="pu_row">

                    <div class="pu_title">
                        {{ view_1.TITLE }}
                    </div>

                    <div v-if="view_1.PRICE > 0"
                         class="pu_price">
                        {{ $ConMoney(view_1.PRICE) }} 원
                    </div>

                    <div v-if="view_1.PRICE < 1"
                         class="pu_price">
                        전화문의
                    </div>

                </div>

            </div>

            <div class="pu_col_lg_12">

                <div class="pu_row">

                    <div class="pu_contents_title">
                        - 제품 설명 -
                    </div>

                    <div class="pu_contents" v-html="$ConHtml(view_1.CONTENTS)">
                    </div>

                </div>

            </div>

        </div>
        <!--//사진-->

        <a class="pu_floating_btn_close" href="javascript:parent.FrameLayer.close();">
            <i class="pu_floating_btn far fa-window-close"></i>
        </a>

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "VIEW"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // 데이터 조회
                this.getViewData();

            }

            // 데이터 조회
            , getViewData(curPage) {

                var qs = this.$GetQueryMap();

                var data = {};
                data["gubun_page"] = this.gubun_page;
                data["no"] = qs.no;

                // API 조회
                this.$SendPost("/vue_api/user/prod/view", data, this.viewDataReturn);
            }

            // 데이터 리턴
            , viewDataReturn(data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1;
                        this.list_1 = data.LIST_1;

                        // 처음 메인 이미지
                        if (this.list_1[0].URL_IMG_ITEM) {
                            this.changeImg(this.list_1[0].URL_IMG_ITEM);
                        }

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                } else {
                    console.log(err);
                }

            }

            // 메인사진 변경
            , changeImg(src) {

                $(".dev_layer_main_img").fadeOut(200, function () {
                    $(".dev_layer_main_img").css('background-image', 'url(' + src + ')');
                    $(".dev_layer_main_img").fadeIn(200, function () { });
                });

            }

        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>

<style scoped>

    .pu_wrapper {
        width: 100vw;
        height: 100vh;
        position:relative;
    }

    .pu_layer {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
    }

        .pu_layer .pu_row {
            padding: 7px 0px;
        }

    .pu_wrapper_img {
        padding-top:5vw;
        width: 100%;
        height: 35%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .pu_layer .pu_layer_main_img {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .pu_layer .pu_layer_navi_ul {
        width: 100%;
        height: 100%;
    }

        .pu_layer .pu_layer_navi_ul .pu_layer_navi_li {
            margin: 0 auto;
            padding-top: 5vw;
            width: 80%;
            height: 20vw;
        }

            .pu_layer .pu_layer_navi_ul .pu_layer_navi_li .pu_layer_navi_img {
                width: 100%;
                height: 100%;
                transition: .5s ease;
                background-size: 100%, 100%, contain;
                background-position: center center;
                background-repeat: no-repeat;
                cursor: pointer;
            }

    .pu_layer .pu_title {
        margin-top: 1vw;
        padding: 1vh 0vw;
        color: #777777;
        font-size: 25px;
        font-weight: bold;
        text-align: center;
    }

    .pu_layer .pu_price {
        text-align: center;
        padding: 1vh 0vw;
        color: #ff8a29;
        font-size: 23px;
        font-weight: bold;
    }

    .pu_layer .pu_contents_title {
        padding: 5px 0px;
        font-size: 22px;
        color: #777777;
        background-color: #f2f2f2;
        text-align: center;
    }

    .pu_layer .pu_contents {
        margin: 0px auto;
        padding: 2vh 2vw;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size: 1.5rem;
        color: #999999;
    }

    .pu_floating_btn_close {
        position: fixed;
        top: 5vw;
        right: 5vw;        
        font-size: 7vw;
        color: #a6a6a6;
        display: block;
    }
</style>
