<template>

    <!--================ Top Menu Area =================-->

    <div class="pu_top">
        <div class="pu_row">
            <div class="pu_contents">

                <!--아래 div 가 있어야 메뉴가 바로바로 바뀜-->
                <div style="display:none;">
                    {{ getBase.userMenuName_1d }}
                </div>
                <!--//아래 div 가 있어야 메뉴가 바로바로 바뀜-->

                <div class="pu_logo">
                    <a href="javascript:;" v-on:click="$PageMove('/')">
                        <img src="/common/img/item/logo_3_300.png" />
                    </a>
                </div>

                <div class="pu_nav">
                    <ul>
                        <li v-for="(d1, idx_d1) in getBase.arrUserMenu_1d" v-bind:key="idx_d1">
                            <a v-bind:class="d1.ACTIVE" href="javascript:;" v-on:click="$PageMove(d1.URL_MAIN)">{{ d1.TITLE }}</a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>

    <!--================ //Top Menu Area =================-->

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                THIS_NAME: "INC TOP"

            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // code url
                this.getMenu1d();

            }

            // url code
            , getMenu1d() {

                // 데이터
                var data = {};
                data["url"] = this.getBase.pathCur;
                data["qs"] = this.getBase.qsCur;

                // API 조회
                this.$SendPost("/vue_api/common/menu/user_1d", data, this.getMenu1dReturn);
            }

            // code url 리턴
            , getMenu1dReturn(data, err) {

                if (data) {

                    if (
                        data.MENU_CODE_1D > 0
                        || this.getBase.pathCur == "/user/home" // 메인은 메뉴 보여줘야함
                    ) {
                        this.setBase({ "userMenuCode_1d": data.MENU_CODE_1D }); // 1뎁스 메뉴코드
                        this.setBase({ "userMenuName_1d": data.MENU_NAME_1D }); // 1뎁스 메뉴명
                        this.setBase({ "arrUserMenu_1d": data.LIST_1 });        // 1뎁스 메뉴
                    }
                    
                    this.setBase({ "userLoginYn": data.USER_LOGIN_YN }); // 로그인 여부
                    this.setBase({ "userNo": data.USER_NO });            // 로그인 번호

                    // this.$PrintLog("getMenu1dReturn : ");
                    // this.$PrintLog(data);
                }
            }
        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {

            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.init();

        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
