<template>

    <div class="pu_wrapper pu_web">

        <!--================ inc head top =================-->
        <inc_user_head />
        <inc_user_top />
        <!--================ //inc head top =================-->

        <div class="pu_board">

            <!--================ inc left =================-->
            <inc_user_left />
            <!--================ //inc left =================-->

            <div class="pu_row">
                <div class="pu_contents pu_min_height_450">

                    <div class="pu_row">
                        <div class="pu_contents">

                            <table class="pu_table_view">
                                <colgroup>
                                    <col width="20%">
                                    <col width="80%">
                                </colgroup>
                                <tbody>

                                    <tr>
                                        <th>제목</th>
                                        <td>
                                            {{ view_1.TITLE }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>내용</th>
                                        <td>
                                            <div class="pu_div_contents" v-html="$ConHtml(view_1.CONTENTS)"></div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>

                    <div class="pu_row" style="margin: 15px 0px;">
                        <div class="pu_contents">

                            <div class="pu_col_lg_1">
                                <a class="pu_btn pu_btn_list" href="javascript:;" v-on:click="$PageMove('./board_gold')">
                                    <i class="fas fa-list-ul"></i> 목록
                                </a>
                            </div>

                            <div class="pu_col_lg_10"></div>

                            <div class="pu_col_lg_1"></div>

                        </div>
                    </div>

                </div>
            </div>

        </div>

        <!--================ inc foot =================-->
        <inc_user_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "VIEW"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                this.getViewData();

            }

            // 데이터 조회
            , getViewData() {

                var qs = this.$GetQueryMap();

                var data = {};
                data["gubun_page"] = this.gubun_page;
                data["no"] = qs.no;

                // API 조회
                this.$SendPost("/vue_api/user/board_gold/view", data, this.viewDataReturn);

            }

            // 데이터 리턴
            , viewDataReturn(data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1; // 뷰

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>