<template>

    <div class="pu_wrapper pu_web">

        <!--================ inc head top =================-->
        <inc_user_head />
        <inc_user_top />
        <!--================ //inc head top =================-->

        <form id="regiForm" onsubmit="return false;">

            <!--전달값-->
            <input type="hidden" name="no" v-model="view_1.NO" />
            <!--//전달값-->

            <div class="pu_board">

                <!--================ inc left =================-->
                <inc_user_left />
                <!--================ //inc left =================-->

                <div class="pu_row">
                    <div class="pu_contents pu_min_height_450">

                        <div class="pu_row">
                            <div class="pu_contents">

                                <table class="pu_table_view">
                                    <colgroup>
                                        <col width="15%">
                                        <col width="35%">
                                        <col width="15%">
                                        <col width="35%">
                                    </colgroup>
                                    <tbody>

                                        <tr>
                                            <th>제목</th>
                                            <td colspan="3">
                                                <label class="pu_input_text_label">
                                                    <input type="text" name="title" v-model="view_1.TITLE"
                                                           class="pu_input_text dev_input_must"
                                                           placeholder="" />
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>이름</th>
                                            <td colspan="3">
                                                <label class="pu_input_text_label">
                                                    <input type="text" name="name" v-model="view_1.NAME"
                                                           class="pu_input_text dev_input_must"
                                                           placeholder="" />
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>비밀번호</th>
                                            <td colspan="3">
                                                <label class="pu_input_text_label">
                                                    <input type="password" name="pw" v-model="view_1.PW"
                                                           class="pu_input_text dev_input_must"
                                                           placeholder="" />
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>핸드폰</th>
                                            <td>
                                                <label class="pu_input_text_label">
                                                    <input type="text" name="phone" v-model="view_1.PHONE"
                                                           class="dev_phone pu_input_text"
                                                           placeholder="" />
                                                </label>
                                            </td>
                                            <th>이메일</th>
                                            <td>
                                                <label class="pu_input_text_label">
                                                    <input type="text" name="email" v-model="view_1.EMAIL"
                                                           class="pu_input_text"
                                                           placeholder="" />
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>문의내용</th>
                                            <td colspan="3">
                                                <textarea name="question" class="pu_input_textarea dev_input_must" v-html="view_1.QUESTION"></textarea>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div class="pu_row" style="margin: 15px 0px;">
                            <div class="pu_contents">

                                <div class="pu_col_lg_1"></div>

                                <div class="pu_col_lg_1">
                                    <a class="pu_btn pu_btn_list" href="javascript:;" v-on:click="$PageMove('./board_qna')">
                                        <i class="fas fa-list-ul"></i> 목록
                                    </a>
                                </div>

                                <div class="pu_col_lg_1">
                                    <a class="dev_btn_delete pu_btn pu_btn_del" v-if="view_1.NO > 0" href="javascript:;">
                                        <i class="far fa-trash-alt"></i> 삭제
                                    </a>
                                </div>

                                <div class="pu_col_lg_7"></div>

                                <div class="pu_col_lg_1">
                                    <a class="dev_btn_submit pu_btn pu_btn_regi" href="javascript:;">
                                        <i class="fas fa-edit"></i> 저장
                                    </a>
                                </div>

                                <div class="pu_col_lg_1"></div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </form>

        <!--================ inc foot =================-->
        <inc_user_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGIFORM"

                , cnt_lim_pic: 5

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                this.getViewData();

            }

            // 데이터 조회
            , getViewData() {

                var qs = this.$GetQueryMap();

                var data = {};
                data["gubun_page"] = this.gubun_page;
                data["no"] = qs.no;

                // API 조회
                this.$SendPost("/vue_api/user/board_qna/view", data, this.viewDataReturn);
            }

            // 데이터 리턴
            , viewDataReturn(data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1; // 뷰

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }

                    // ck editor 세팅
                    this.setCkEditor();
                }
            }

            // 데이터 저장
            , saveData() {

                // 제목
                var title = $("[name='title']");
                if (!title.val().trim()) {
                    alert("제목을 입력해주세요");
                    title.focus();
                    return false;
                }

                // 이름
                var name = $("[name='name']");
                if (!name.val().trim()) {
                    alert("이름을 입력해주세요");
                    name.focus();
                    return false;
                }

                // 비밀번호
                var pw = $("[name='pw']");
                if (!pw.val().trim()) {
                    alert("비밀번호를 입력해주세요");
                    pw.focus();
                    return false;
                }

                // 문의내용
                CKEDITOR.instances.question.updateElement();
                var question = $("[name='question']");
                if (!question.val().trim()) {
                    alert("문의내용을 입력해주세요");
                    question.focus();
                    return false;
                }

                if (confirm(INFO_CONF_SAVE)) {

                    // dimm 보이기
                    this.$ShowDimmLogo();

                    var data = this.$FormToMap($('#regiForm'));
                    data["gubun_page"] = this.gubun_page;

                    // API 조회
                    this.$SendPost("/vue_api/user/board_qna/save", data, this.saveEventReturn);
                }
            }

            // 저장 데이터
            , saveEventReturn(data, err) {

                // dimm 숨기기
                HideWhiteDimm();
            }

            // 삭제
            , deleteData() {

                if (confirm(INFO_CONF_DELETE)) {

                    // dimm 보이기
                    this.$ShowDimmLogo();

                    // 데이터
                    var data = {};
                    data["gubun_page"] = this.gubun_page;
                    data["no"] = this.view_1.NO;

                    // API 조회
                    this.$SendPost("/vue_api/user/board_qna/del", data, this.deleteDataReturn);

                }
            }

            // 삭제 데이터
            , deleteDataReturn(data, err) {

                // dimm 숨기기
                this.$HideDimmLogo();
            }

            // ck editor 세팅
            , setCkEditor() {

                setTimeout(() => {

                    try {
                        setCkEditorDefault("question", "board_qna", this.cnt_lim_pic, ".jpeg,.jpg,.png");
                    } catch (err) {
                        this.setCkEditor();
                    }

                }, 100);
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>