<template>

    <!--================ Head Menu Area =================-->

    <div></div>

    <!--================ //Head Menu Area =================-->

</template>

<script>

    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        },

        data() {
            return {

                THIS_NAME: "INC HEAD"

            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // 현재 경로 & 쿼리스트링
                this.setBase({ "pathCur": window.location.pathname });
                this.setBase({ "qsCur": window.location.search });
            },
        },

        beforeCreate() {

            // common src 리셋
            $(".dev_common_src_user").remove();

            // common src 추가
            var $arrCommonSrc = $(".dev_common_src_zadmin");
            if (!$arrCommonSrc || $arrCommonSrc.length == 0) {

                // import css
                var arrCss = [
                    "/common/css/custom/zadmin_custom.css"
                ];
                arrCss.forEach(css => {
                    let tag = document.createElement("link");
                    tag.setAttribute("class", "dev_common_src_zadmin");
                    tag.setAttribute("rel", "stylesheet")
                    tag.setAttribute("href", css);
                    document.head.appendChild(tag);
                });

                // import script
                var arrScript = [
                ];
                arrScript.forEach(script => {
                    let tag = document.createElement("script");
                    tag.setAttribute("class", "dev_common_src_zadmin");
                    tag.setAttribute("src", script);
                    document.head.appendChild(tag);
                });

            }

        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {

            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.init();

        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
