<template>

    <div class="pu_wrapper pu_web">

        <!--================ inc head top =================-->
        <inc_user_head />
        <inc_user_top />
        <!--================ //inc head top =================-->

        <div class="pu_board">

            <!--================ inc left =================-->
            <inc_user_left />
            <!--================ //inc left =================-->

            <div class="pu_row">
                <div class="pu_contents pu_min_height_700">

                    <div class="pu_row">
                        <div class="pu_contents" style="text-align: center; padding: 115px 0px;">

                            <img class="pu_img_contents" src="/common/img/item/buy_process_gold.jpg" />

                        </div>
                    </div>

                </div>
            </div>

        </div>

        <!--================ inc foot =================-->
        <inc_user_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // dimm 숨기기
                this.$HideDimmLogo();

            }

        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>