<template>

    <div class="pu_login_wrapper pu_row">

        <div class="pu_login_body">

            <form id="regiForm" onsubmit="return false">

                <div class="pu_col_lg_12 pu_box_header">
                    <h3 class="box-title">
                        <img src="/common/img/item/logo_3_300.png" alt="로고" style="width: 180px;" />
                    </h3>
                </div>

                <div class="pu_col_lg_12 pu_box_body">
                    <div class="pu_col_lg_12">
                        <label class="pu_col_lg_2">ID</label>
                        <input type="text" name="id" class="pu_col_lg_9" v-model="id" autocomplete="off" style="border: solid 1px #a6a6a6; border-radius: 5px; color: #a6a6a6; text-align:center">
                    </div>

                    <div class="pu_col_lg_12" style="margin-top:10px">
                        <label class="pu_col_lg_2">PW</label>
                        <input type="password" name="pw" class="pu_col_lg_9" v-model="pw" autocomplete="off" style="border: solid 1px #a6a6a6; border-radius: 5px; color: #a6a6a6; text-align:center">
                    </div>
                </div>

                <div class="pu_col_lg_12 pu_box_footer" style="margin-top:10px">
                    <a class="dev_btn_submit pu_btn_login btn btn-primary" href="javascript:;"
                       style="width: 240px; height: 45px; line-height: 28px; font-size: 25px;background-color: #ef8c29; border-color: #ef8c29;">
                        Login
                    </a>
                </div>
            </form>

        </div>

        <div class="pu_login_footer">
            &copy; Copyright <a href="http://hyper-nomad.com">HyperNomad</a>. All Rights Reserved
        </div>

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGIFORM"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

                , id: "" // 아이디
                , pw: "" // 비밀번호

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // enter 키 이벤트
                this.$SetEventEnter("id", function () { $("[name='pw']").focus(); });
                this.$SetEventEnter("pw", this.saveData);

                // dimm 숨기기
                this.$HideDimmLogo();

            }

            // 저장
            , saveData () {

                // 비밀번호
                var id = $("[name='id']");
                if (!id.val().trim()) {
                    alert("아이디를 입력해주세요");
                    id.focus();
                    return false;
                }

                // 비밀번호
                var pw = $("[name='pw']");
                if (!pw.val().trim()) {
                    alert("비밀번호를 입력해주세요");
                    pw.focus();
                    return false;
                }

                // dimm 보이기
                this.$ShowDimmLogo();

                var qs = this.$GetQueryMap();
                var data = this.$FormToMap($("#regiForm"));
                data["gubun_page"] = this.gubun_page;

                // API 조회
                this.$SendPost("/vue_api/zadmin/login", data, this.saveEventReturn);

            }

            // 저장 데이터
            , saveEventReturn (data, err) {

                // dimm 숨기기
                this.$HideDimmLogo();

            }

        }

        , beforeCreate() {

            // common src 리셋
            $(".dev_common_src_user").remove();

            // common src 추가
            var $arrCommonSrc = $(".dev_common_src_zadmin");
            if (!$arrCommonSrc || $arrCommonSrc.length == 0) {

                // import css
                var arrCss = [
                    "/common/css/custom/zadmin_custom.css"
                ];
                arrCss.forEach(css => {
                    let tag = document.createElement("link");
                    tag.setAttribute("class", "dev_common_src_zadmin");
                    tag.setAttribute("rel", "stylesheet")
                    tag.setAttribute("href", css);
                    document.head.appendChild(tag);
                });

                // import script
                var arrScript = [
                ];
                arrScript.forEach(script => {
                    let tag = document.createElement("script");
                    tag.setAttribute("class", "dev_common_src_zadmin");
                    tag.setAttribute("src", script);
                    document.head.appendChild(tag);
                });

            }

        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>