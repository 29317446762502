<template>

    <!--================ Top Menu Area =================-->

    <div class="pu_top">
        <div class="pu_row">
            <div class="pu_contents">

                <!--아래 div 가 있어야 메뉴가 바로바로 바뀜-->
                <div style="display:none;">
                    {{ getBase.adminMenuName_1d }}
                </div>
                <!--//아래 div 가 있어야 메뉴가 바로바로 바뀜-->

                <div class="pu_logo">
                    <a>
                        <img src="/common/img/item/logo_3_300.png" />
                    </a>
                </div>

                <div class="pu_nav">
                    <ul>
                        <li v-for="(d1, idx_d1) in getBase.arrAdminMenu_1d" v-bind:key="idx_d1">
                            <a v-bind:class="d1.ACTIVE" href="javascript:;" v-on:click="$PageMove(d1.URL_MAIN)">{{ d1.TITLE }}</a>
                        </li>
                    </ul>
                </div>

                <div class="pu_nav_def">
                    <ul>
                        <li v-if="getBase.adminLoginYn == 'N'">
                            <a href="javascript:;" v-on:click="$PageMove('./login')">로그인</a>
                        </li>
                        <li v-if="getBase.adminLoginYn == 'Y'">
                            <a href="javascript:;" v-on:click="$PageMove('./login')">로그아웃</a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>

    <!--================ //Top Menu Area =================-->

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                THIS_NAME: "INC TOP"

            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // code url
                this.getMenu1d();

            }

            // url code
            , getMenu1d() {

                // 데이터
                var data = {};
                data["url"] = this.getBase.pathCur;
                data["qs"] = this.getBase.qsCur;

                // API 조회
                this.$SendPost("/vue_api/common/menu/zadmin_1d", data, this.getMenu1dReturn);
            }

            // code url 리턴
            , getMenu1dReturn(data, err) {

                if (data) {

                    if (data.MENU_CODE_1D > 0) {
                        this.setBase({ "adminMenuCode_1d": data.MENU_CODE_1D }); // 1뎁스 메뉴코드
                        this.setBase({ "adminMenuName_1d": data.MENU_NAME_1D }); // 1뎁스 메뉴명
                        this.setBase({ "arrAdminMenu_1d": data.LIST_1 });        // 1뎁스 메뉴
                    }
                    
                    this.setBase({ "adminLoginYn": data.ADMIN_LOGIN_YN });    // 로그인 여부
                    this.setBase({ "adminNo": data.ADMIN_NO });               // 로그인 번호
                }
            }
        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {

            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.init();

        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
