<template>

    <div class="pu_wrapper">

        <!--================ inc head top =================-->
        <inc_zadmin_head />
        <inc_zadmin_top />
        <inc_zadmin_title />
        <!--================ //inc head top =================-->

        <form id="regiForm">

            <div class="pu_board">
                <div class="pu_row">
                    <div class="pu_contents">

                        <!--================ inc left =================-->
                        <inc_zadmin_left />
                        <!--================ //inc left =================-->

                        <div class="pu_body">
                            <div class="pu_row">
                                <div class="pu_contents">

                                    <div class="pu_row">
                                        <div class="pu_contents">

                                            <table class="pu_table_view" style="width: 700px; margin: 0px auto;">

                                                <colgroup>
                                                    <col width="200px">
                                                    <col width="250px">
                                                    <col width="250px">
                                                </colgroup>

                                                <thead>
                                                    <tr>
                                                        <th>다이아몬드 매입시세</th>
                                                        <th>우신</th>
                                                        <th>현대</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <th>0.21ct (G,VVS1)</th>
                                                        <td>
                                                            <label class="pu_input_text_label">
                                                                <input type="text" name="dia_21_ws_now" v-model="view_1.DIA_21_WS_NOW"
                                                                       class="pu_input_text dev_input_must dev_numnumnum"
                                                                       placeholder="" /><span>원</span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label class="pu_input_text_label">
                                                                <input type="text" name="dia_21_hd_now" v-model="view_1.DIA_21_HD_NOW"
                                                                       class="pu_input_text dev_input_must dev_numnumnum"
                                                                       placeholder="" /><span>원</span>
                                                            </label>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>0.31ct (G,VVS1)</th>
                                                        <td>
                                                            <label class="pu_input_text_label">
                                                                <input type="text" name="dia_31_ws_now" v-model="view_1.DIA_31_WS_NOW"
                                                                       class="pu_input_text dev_input_must dev_numnumnum"
                                                                       placeholder="" /><span>원</span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label class="pu_input_text_label">
                                                                <input type="text" name="dia_31_hd_now" v-model="view_1.DIA_31_HD_NOW"
                                                                       class="pu_input_text dev_input_must dev_numnumnum"
                                                                       placeholder="" /><span>원</span>
                                                            </label>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>0.51ct (G,VVS1)</th>
                                                        <td>
                                                            <label class="pu_input_text_label">
                                                                <input type="text" name="dia_51_ws_now" v-model="view_1.DIA_51_WS_NOW"
                                                                       class="pu_input_text dev_input_must dev_numnumnum"
                                                                       placeholder="" /><span>원</span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label class="pu_input_text_label">
                                                                <input type="text" name="dia_51_hd_now" v-model="view_1.DIA_51_HD_NOW"
                                                                       class="pu_input_text dev_input_must dev_numnumnum"
                                                                       placeholder="" /><span>원</span>
                                                            </label>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>0.71ct (G,VVS1)</th>
                                                        <td>
                                                            <label class="pu_input_text_label">
                                                                <input type="text" name="dia_71_ws_now" v-model="view_1.DIA_71_WS_NOW"
                                                                       class="pu_input_text dev_input_must dev_numnumnum"
                                                                       placeholder="" /><span>원</span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label class="pu_input_text_label">
                                                                <input type="text" name="dia_71_hd_now" v-model="view_1.DIA_71_HD_NOW"
                                                                       class="pu_input_text dev_input_must dev_numnumnum"
                                                                       placeholder="" /><span>원</span>
                                                            </label>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>


                                    <div class="pu_row">
                                        <div class="pu_contents">

                                            <div class="pu_row_horizon">

                                                <div class="pu_col_lg_11"></div>

                                                <div class="pu_col_lg_1">
                                                    <a class="dev_btn_submit pu_btn pu_btn_regi">
                                                        <i class="fas fa-edit"></i> 저장
                                                    </a>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </form>

        <!--================ inc foot =================-->
        <inc_zadmin_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGIFORM"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                this.getViewData();

            }

            // 데이터 조회
            , getViewData () {

                var qs = this.$GetQueryMap();
                var data = {};
                data["gubun_page"] = this.gubun_page;

                // API 조회
                this.$SendPost("/vue_api/zadmin/set_price_dia/view", data, this.viewDataReturn);

            }

            // 데이터 리턴
            , viewDataReturn (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1; // 뷰

                        // dimm 숨기기
                        this.$HideDimmLogo();

                    }

                }

            }

            // 저장
            , saveData () {

                // 0.21ct --------------------------------------------------------

                var dia_21_ws_now = $("[name='dia_21_ws_now']");
                if (!dia_21_ws_now.val().trim()) {
                    alert("0.21ct (G,VVS1) 우신 입력해주세요");
                    dia_21_ws_now.focus();
                    return false;
                }

                var dia_21_hd_now = $("[name='dia_21_hd_now']");
                if (!dia_21_hd_now.val().trim()) {
                    alert("0.21ct (G,VVS1) 현대 입력해주세요");
                    dia_21_hd_now.focus();
                    return false;
                }

                // 0.31ct --------------------------------------------------------

                var dia_31_ws_now = $("[name='dia_31_ws_now']");
                if (!dia_31_ws_now.val().trim()) {
                    alert("0.31ct (G,VVS1) 우신 입력해주세요");
                    dia_31_ws_now.focus();
                    return false;
                }

                var dia_31_hd_now = $("[name='dia_31_hd_now']");
                if (!dia_31_hd_now.val().trim()) {
                    alert("0.31ct (G,VVS1) 현대 입력해주세요");
                    dia_31_hd_now.focus();
                    return false;
                }

                // 0.51ct --------------------------------------------------------

                var dia_51_ws_now = $("[name='dia_51_ws_now']");
                if (!dia_51_ws_now.val().trim()) {
                    alert("0.51ct (G,VVS1) 우신 입력해주세요");
                    dia_51_ws_now.focus();
                    return false;
                }

                var dia_51_hd_now = $("[name='dia_51_hd_now']");
                if (!dia_51_hd_now.val().trim()) {
                    alert("0.51ct (G,VVS1) 현대 입력해주세요");
                    dia_51_hd_now.focus();
                    return false;
                }

                // 0.71ct --------------------------------------------------------

                var dia_71_ws_now = $("[name='dia_71_ws_now']");
                if (!dia_71_ws_now.val().trim()) {
                    alert("0.71ct (G,VVS1) 우신 입력해주세요");
                    dia_71_ws_now.focus();
                    return false;
                }

                var dia_71_hd_now = $("[name='dia_71_hd_now']");
                if (!dia_71_hd_now.val().trim()) {
                    alert("0.71ct (G,VVS1) 현대 입력해주세요");
                    dia_71_hd_now.focus();
                    return false;
                }

                if (confirm(INFO_CONF_SAVE)) {

                    // dimm 보이기
                    this.$ShowDimmLogo();

                    var data = this.$FormToMap($('#regiForm'));
                    data["gubun_page"] = this.gubun_page;

                    // API 조회
                    this.$SendPost("/vue_api/zadmin/set_price_dia/save", data, this.saveEventReturn);

                }

            }

            // 저장 데이터
            , saveEventReturn (data, err) {

                // dimm 숨기기
                this.$HideDimmLogo();

            }

        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>