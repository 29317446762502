<template>

    <div class="pu_wrapper">

        <!--================ inc head top =================-->
        <inc_zadmin_head />
        <inc_zadmin_top />
        <inc_zadmin_title />
        <!--================ //inc head top =================-->

        <div class="pu_board">
            <div class="pu_row">
                <div class="pu_contents">

                    <!--================ inc left =================-->
                    <inc_zadmin_left />
                    <!--================ //inc left =================-->

                    <div class="pu_body">
                        <div class="pu_row">
                            <div class="pu_contents">

                                <form id="searchForm">

                                    <div class="pu_row pu_bottom_line">
                                        <div class="pu_contents">

                                            <div class="pu_row_horizon">
                                                <div class="pu_col_lg_1"></div>

                                                <div class="pu_col_lg_2">
                                                    <select name="gubun" class="dev_option pu_input_select dev_input_must">
                                                        <option value="ALL">전체제품</option>
                                                        <option value="A1">아기반지</option>
                                                        <option value="A2">아기팔찌</option>
                                                        <option value="A3">금수저</option>
                                                        <option value="B1">골드바</option>
                                                        <option value="C1">실버바</option>
                                                        <option value="D1">순금기념품</option>
                                                    </select>
                                                </div>

                                                <div class="pu_col_lg_2">
                                                    <select name="search_gubun" class="pu_input_select dev_input_must">
                                                        <option value="title">제품명</option>
                                                    </select>
                                                </div>

                                                <div class="pu_col_lg_6">
                                                    <input type="text" name="search_text" class="pu_input_text"
                                                           placeholder="검색어를 입력해주세요" />
                                                </div>

                                                <div class="pu_col_lg_2">
                                                    <a class="dev_btn_search pu_btn_search">
                                                        <i class="fas fa-search"></i> 검색
                                                    </a>
                                                </div>

                                                <div class="pu_col_lg_1"></div>
                                            </div>

                                        </div>
                                    </div>

                                </form>

                                <div class="pu_row">
                                    <div class="pu_contents">

                                        <table class="pu_table_list">
                                            <colgroup>
                                                <col width="100px">
                                                <col width="10%">
                                                <col width="*">
                                                <col width="10%">
                                                <col width="10%">
                                                <col width="10%">
                                                <col width="10%">
                                                <col width="10%">
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>번호</th>
                                                    <th>구분</th>
                                                    <th>제품명</th>
                                                    <th>돈(무게)</th>
                                                    <th>세공비(원)</th>
                                                    <th>가격</th>
                                                    <th>공개여부</th>
                                                    <th>등록일</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(row, idx) in list_1">
                                                    <td>{{ getRowNum(idx) }}</td>
                                                    <td>{{ row.GUBUN_NAME }}</td>
                                                    <td class="pu_text_left">
                                                        <a class="" href="javascript:;" v-on:click="$PageMove('./prod_view?no=' + row.NO)">
                                                            {{ row.TITLE }}
                                                        </a>
                                                    </td>
                                                    <td>{{ row.WEIGHT }}</td>
                                                    <td>{{ $ConMoney(row.COST_CARVE) }}</td>
                                                    <td>{{ $ConMoney(row.PRICE) }}</td>
                                                    <td>{{ $ConYN(row.SHOW_YN) }}</td>
                                                    <td>{{ row.REGI_DATE }}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                                <div class="pu_row">
                                    <div class="pu_contents" v-html="paging">
                                    </div>
                                </div>

                                <div class="pu_row">
                                    <div class="pu_contents">

                                        <div class="pu_row_horizon">

                                            <div class="pu_col_lg_11"></div>

                                            <div class="pu_col_lg_1">
                                                <a class="pu_btn pu_btn_regi" href="javascript:;" v-on:click="$PageMove('./prod_regi')">
                                                    <i class="fas fa-edit"></i> 등록
                                                </a>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!--================ inc foot =================-->
        <inc_zadmin_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                this.getSearchData(1);

            }

            // 데이터 조회
            , getSearchData: function (curPage) {

                // qs
                var qs = this.$GetQueryMap();

                // 폼
                var data = this.$FormToMap($("#searchForm"));
                data["gubun_page"] = this.gubun_page;
                data["curPage"] = curPage;

                // API 조회
                this.$SendPost("/vue_api/zadmin/prod/list", data, this.searchDataReturn);

            }

            // 데이터 리턴
            , searchDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.rownum = data.ROWNUM;
                        this.paging = data.PAGING;
                        this.list_1 = data.LIST_1;
                        this.list_2 = data.LIST_2;
                        this.list_3 = data.LIST_3;

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>