<template>

    <div class="pu_wrapper pu_web">

        <!--================ inc head top =================-->
        <inc_user_head />
        <inc_user_top />
        <!--================ //inc head top =================-->

        <div class="pu_board">

            <!--================ inc left =================-->
            <inc_user_left />
            <!--================ //inc left =================-->

            <div class="pu_row">
                <div class="pu_contents pu_min_height_450">

                    <div class="pu_row">
                        <div class="pu_contents">

                            <table class="pu_table_list">
                                <colgroup>
                                    <col width="100px">
                                    <col width="100%">
                                    <col width="100px">
                                    <col width="150px">
                                    <col width="150px">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>제목</th>
                                        <th>답변</th>
                                        <th>작성자</th>
                                        <th>작성일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, idx) in list_1">
                                        <td>{{ getRowNum(idx) }}</td>
                                        <td class="pu_text_left">
                                            <a class="" href="javascript:;" v-on:click="$PageMove('./board_qna_check?next=view&no=' + row.NO)">
                                                {{ row.TITLE }}
                                            </a>
                                        </td>
                                        <td>{{ $ConYN(row.ANSWER_YN) }}</td>
                                        <td>{{ row.NAME }}</td>
                                        <td>{{ row.REGI_DATE }}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>

                    <div class="pu_row">
                        <div class="pu_contents">

                            <div class="pu_row_horizon">

                                <div class="pu_col_lg_1"></div>
                                <div class="pu_col_lg_1"></div>
                                <div class="pu_col_lg_8"></div>
                                <div class="pu_col_lg_1">
                                    <a class="pu_btn pu_btn_regi" href="javascript:;" v-on:click="$PageMove('./board_qna_regi')">
                                        <i class="fas fa-edit"></i> 등록
                                    </a>
                                </div>
                                <div class="pu_col_lg_1"></div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>

        <!--================ inc foot =================-->
        <inc_user_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                this.getSearchData(1);

            }

            // 데이터 조회
            , getSearchData(curPage) {

                var data = {};
                data["gubun_page"] = this.gubun_page;
                data["curPage"] = curPage;

                // API 조회
                this.$SendPost("/vue_api/user/board_qna/list", data, this.searchDataReturn);

            }

            // 데이터 리턴
            , searchDataReturn(data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.rownum = data.ROWNUM;
                        this.paging = data.PAGING;
                        this.list_1 = data.LIST_1;
                        this.list_2 = data.LIST_2;
                        this.list_3 = data.LIST_3;

                        // dimm 숨기기
                        this.$HideDimmLogo();

                    }
                }
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>