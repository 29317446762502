<template>

    <!--================ Left Menu Area =================-->

    <div class="pu_row pu_tap_top">
        <div class="pu_contents">

            <!--아래 div 가 있어야 메뉴가 바로바로 바뀜-->
            <div style="display:none;">
                {{ getBase.userMenuName_2d }}
            </div>
            <!--//아래 div 가 있어야 메뉴가 바로바로 바뀜-->

            <ul>
                <li v-for="(d2, idx_d2) in getBase.arrUserMenu_2d">
                    <a class="pu_tap_menu" v-bind:class="d2.ACTIVE" href="javascript:;" v-on:click="$PageMove(d2.URL_MAIN)">{{ d2.TITLE }}</a>
                </li>
            </ul>
        </div>
    </div>

    <!--================ //Left Menu Area =================-->

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                THIS_NAME: "INC LEFT"

            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // code url
                this.getMenu2d();

            }

            // url code
            , getMenu2d() {

                // 데이터
                var data = {};
                data["url"] = this.getBase.pathCur;
                data["qs"] = this.getBase.qsCur;
                data["menu_code_1d"] = this.getBase.userMenuCode_1d;

                // API 조회
                this.$SendPost("/vue_api/common/menu/user_2d", data, this.getMenu2dReturn);
            }

            // code url 리턴
            , getMenu2dReturn(data, err) {

                if (data) {

                    if (data.MENU_CODE_2D > 0) {
                        this.setBase({ "userMenuCode_2d": data.MENU_CODE_2D }); // 2뎁스 메뉴코드
                        this.setBase({ "userMenuName_2d": data.MENU_NAME_2D }); // 2뎁스 메뉴명
                    }

                    this.setBase({ "arrUserMenu_2d": data.LIST_1 });         // 2뎁스 메뉴

                    // this.$PrintLog("getMenu2dReturn : ");
                    // this.$PrintLog(data);
                }
            }
        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {

            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.init();

        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
